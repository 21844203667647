export enum AvenueRouteEnum {
  AlertCreate = '/alert/create',
  GroupLibrary = '/group-library',
  HeroBanner = '/hero-banner',
  HeroBannerCreate = '/hero-banner/create',
  HeroBannerEdit = '/hero-banner/edit',
  Home = '/',
  Jobs = '/jobs',
  Library = '/library',
  NewsletterBanner = '/newsletter-banner',
  NewsletterBannerCreate = '/newsletter-banner/create',
  NewsletterBannerEdit = '/newsletter-banner/edit',
  NotFound = '/404',
  ProductOfTheWeekGallery = '/product-of-the-week/gallery',
  Profile = '/profile',
  ProfilePreferences = '/profile/preferences',
  ProfileSubscriptions = '/profile/subscriptions',
  ProfileManageAlerts = '/profile/manage-alerts',
  ProfileManageBanners = '/profile/manage-banners',
  ProfileManageFeed = '/profile/manage-feed',
  ProfileManageLibrary = '/profile/manage-library',
  ProfileManageInsightsDatabase = '/profile/manage-insights-database',
}

export enum InnovationRouteEnum {
  Home = '/product-innovation',
  Learning = '/product-innovation/learning',
  TrainingArticle = '/product-innovation/learning/training',
}
