export const getCleanSegmentName = (
  originalSegmentName: string,
  segmentId: string
) => {
  switch (segmentId) {
    // Entrance systems
    case 'segment-135':
      return 'BSPS';
    case 'segment-140':
      return 'BSDA';
    case 'segment-137':
      return 'BSP';
    case 'segment-139':
      return 'BSI';
    case 'segment-42':
      return 'Central Units';
    // Global solutions
    case 'segment-67':
      return 'Production';
    case 'segment-78':
      return 'Marine';
    case 'segment-108':
      return 'Global Functions';
    case 'segment-118':
      return 'Key and asset management';
    case 'segment-122':
      return 'Critical Infrastructure';
    case 'segment-124':
      return 'Hospitality';
    case 'segment-126':
      return 'Senior Care';
    case 'segment-136':
      return 'Construction';
    case 'segment-176':
      return 'Self Storage';
    default:
      return originalSegmentName;
  }
};

export const getOrginalSegmentName = (
  cleanSegmentName: string,
  segmentId: string
) => {
  switch (segmentId) {
    // Entrance systems
    case 'segment-135':
      return 'Business Segment Perimeter Security';
    case 'segment-140':
      return 'Business Segment Doors & Automation';
    case 'segment-137':
      return 'Business Segment Pedestrian';
    case 'segment-139':
      return 'Business Segment Industrial';
    case 'segment-42':
      return 'AAES Central Units';
    // Global solutions
    case 'segment-67':
      return 'Production Group';
    case 'segment-78':
      return 'Marine Gr';
    case 'segment-108':
      return 'Global Functions Group';
    case 'segment-118':
      return 'Traka Gr';
    case 'segment-122':
      return 'Critical Infrastructure Gr';
    case 'segment-124':
      return 'Hospitality Gr';
    case 'segment-126':
      return 'Senior Care Gr';
    case 'segment-136':
      return 'Construction Gr';
    case 'segment-176':
      return 'Self Storage Gr';
    default:
      return cleanSegmentName;
  }
};
