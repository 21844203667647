import Stack from '@mui/material/Stack';
import { Consumer } from 'components/CreateOrEditArticle';
import { UserSearchSelect } from 'componentsNew';
import { translations } from 'translations';

const ContactSearchSelector = () => {
  return (
    <Consumer>
      {({ onChangeArticle, updatedArticle, article }) => {
        return (
          <Stack>
            <p>{translations.contact}</p>
            <UserSearchSelect
              placeholder={translations.searchEmployees}
              value={
                updatedArticle.contactPersons || article.contactPersons || []
              }
              onChange={(users) =>
                onChangeArticle({
                  contactPersons: users,
                })
              }
            />
          </Stack>
        );
      }}
    </Consumer>
  );
};

export { ContactSearchSelector };
