import Skeleton from '@mui/material/Skeleton';
import Stack, { StackProps } from '@mui/material/Stack';

type LearningFilterSkeletonProps = {
  sx?: StackProps['sx'];
};

const LearningFilterSkeleton = ({ sx }: LearningFilterSkeletonProps) => {
  return (
    <Stack sx={sx}>
      <Skeleton
        variant="rounded"
        animation="wave"
        sx={(theme) => ({
          height: { xs: '2.5rem', md: '40rem' },
          margin: { xs: `${theme.spacing('xxs')} 0`, md: 0 },
          flexShrink: 0,
        })}
      />
    </Stack>
  );
};

export { LearningFilterSkeleton };
