import Stack from '@mui/material/Stack';
import { InnovationLearningListingItemTypeEnum } from 'api/find/types';
import fallbackBackgroundImage from 'assets/images/innovation-article-image-background-learning.png';

import { ReactComponent as ChartOnBoard } from './ChartOnBoard.svg';
import { ReactComponent as Document } from './Document.svg';
import { ReactComponent as Microphone } from './Microphone.svg';
import { ReactComponent as VideoPlayer } from './VideoPlayer.svg';

type ArticleImageFallbackProps = {
  type: InnovationLearningListingItemTypeEnum;
};

const ArticleImageFallback = ({ type }: ArticleImageFallbackProps) => {
  let Icon = null;
  switch (type) {
    case InnovationLearningListingItemTypeEnum.CaseStudy:
      Icon = Document;
      break;
    case InnovationLearningListingItemTypeEnum.Podcast:
      Icon = VideoPlayer;
      break;
    case InnovationLearningListingItemTypeEnum.Training:
      Icon = ChartOnBoard;
      break;
    case InnovationLearningListingItemTypeEnum.Webinar:
      Icon = Microphone;
  }
  return (
    <Stack
      sx={{
        height: '100%',
        width: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        position: 'absolute',
        backgroundImage: `url(${fallbackBackgroundImage})`,
        '> svg': {
          height: { xs: '40px', md: '96px' },
          width: { xs: '40px', md: '96px' },
        },
      }}
    >
      {Icon && <Icon />}
    </Stack>
  );
};

export { ArticleImageFallback };
