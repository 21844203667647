import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useMemo } from 'react';

function useBreakpoints() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));
  const isMobile = useMediaQuery(theme.breakpoints.down('md'));
  return useMemo(() => ({ isDesktop, isMobile }), [isDesktop, isMobile]);
}

export { useBreakpoints };
