import {
  JobFilterName,
  JobListingFilter,
  JobListingFilterIds,
} from 'api/jobs/types';

export type JobListRequestParams = {
  user: {
    countryName?: string;
    departmentName?: string;
  };
  page: number;
  filter: JobListingFilterIds;
  search: string;
};

export type JobRequestParams = {
  id: string;
  user: {
    countryName?: string;
    departmentName?: string;
  };
};

export const PAGINATION_DEFAULT_LIMIT = 15;
export const PAGINATION_DEFAULT_PAGE = 1;

export const ALL_FILTER_NAMES = Object.values(JobFilterName) as JobFilterName[];

export const EMPTY_AVAILABLE_FILTER: JobListingFilter = {
  locations: [],
  states: [],
  categories: [],
  seniorityLevels: [],
};

export const EMPTY_ACTIVE_FILTER: JobListingFilterIds = {
  locations: [],
  states: [],
  categories: [],
  seniorityLevels: [],
};

const getJobRequestString = (params: JobRequestParams) => {
  const { user } = params;

  let str = '';

  if (user.countryName) {
    str = str.concat(`?user[countryname]=${user.countryName}`);
  }

  if (user.departmentName) {
    if (str === '') {
      str = '?';
    } else {
      str = str.concat('&');
    }
    str = str.concat(`user[departmentname]=${user.departmentName}`);
  }

  return str;
};

const getJobListRequestString = (params: JobListRequestParams) => {
  const { user, filter, search, page } = params;

  let str = '';
  str = str.concat(`?page[limit]=${PAGINATION_DEFAULT_LIMIT}`);
  str = str.concat(`&page[offset]=${page - 1}`);

  if (user.countryName) {
    str = str.concat(`&user[countryname]=${user.countryName}`);
  }
  if (user.departmentName) {
    str = str.concat(`&user[departmentname]=${user.departmentName}`);
  }
  if (filter.locations.length) {
    str = str.concat(
      `&filter[location.id]=${JSON.stringify(
        filter.locations.map((id) => Number(id))
      )}`
    );
  }
  if (filter.states.length) {
    str = str.concat(
      `&filter[state.id]=${JSON.stringify(
        filter.states.map((id) => Number(id))
      )}`
    );
  }
  if (filter.categories.length) {
    str = str.concat(
      `&filter[category.id]=${JSON.stringify(
        filter.categories.map((id) => Number(id))
      )}`
    );
  }
  if (filter.seniorityLevels.length) {
    str = str.concat(
      `&filter[senioritylevel.id]=${JSON.stringify(
        filter.seniorityLevels.map((id) => Number(id))
      )}`
    );
  }

  if (search.length) {
    str = str.concat(`&search=${search}`);
  }

  return str;
};

const parseFilter = (filterString: string) => {
  try {
    if (!filterString) {
      return EMPTY_ACTIVE_FILTER;
    }
    const filterObject = JSON.parse(filterString);
    if (typeof filterObject !== 'object') {
      return EMPTY_ACTIVE_FILTER;
    }
    const filter = { ...EMPTY_ACTIVE_FILTER };
    ALL_FILTER_NAMES.forEach((name) => {
      const filterValue = filterObject[name];
      if (filterValue) {
        filter[name] = filterValue;
      }
    });
    return filter;
  } catch {
    return EMPTY_ACTIVE_FILTER;
  }
};

const parseSearch = (searchString: string) => {
  return searchString ? (JSON.parse(searchString) as string) : '';
};

const stringifyFilter = (filter: JobListingFilterIds) => {
  const cleanedFilter: Partial<JobListingFilterIds> = {};
  ALL_FILTER_NAMES.forEach((name) => {
    const ids = filter[name];
    if (ids.length) {
      cleanedFilter[name] = ids;
    }
  });
  const isEmpty = !Object.keys(cleanedFilter).length;
  return isEmpty ? '' : JSON.stringify(cleanedFilter);
};

const stringifySearch = (search: string) => {
  return search ? JSON.stringify(search) : '';
};

export {
  getJobListRequestString,
  getJobRequestString,
  parseFilter,
  parseSearch,
  stringifyFilter,
  stringifySearch,
};
