import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useCallback, useMemo } from 'react';
import { getSuggestions } from 'utils/misc/autoSuggest';

import Suggestion from '../../api/models/Suggestion';
import { SearchSelect, SearchSelectProps } from './SearchSelect';
import { SelectItem } from './SelectMenuItem';

export type User = {
  id: string;
  name: string;
  email?: string;
};

export type UserSearchSelectProps = {
  value: User[];
} & Omit<SearchSelectProps<User>, 'search' | 'value'>;

const UserSearchSelect = ({ value, ...selectProps }: UserSearchSelectProps) => {
  const transformToSelectItems = useCallback((users: User[]) => {
    return users.map((user) => {
      const item: SelectItem<User> = {
        name: user.name,
        value: user.id,
        data: user,
        element: (
          <Stack sx={{ wordBreak: 'break-word' }}>
            <Typography variant="body1">{user.name}</Typography>
            {user.email && (
              <Typography variant="body2">{user.email}</Typography>
            )}
          </Stack>
        ),
      };
      return item;
    });
  }, []);

  const search = useCallback(
    async (value: string) => {
      const filter = { type: 'person' };

      const suggestions = ((await getSuggestions(value, filter, false)) ||
        []) as Suggestion[];

      const users = suggestions
        .map((suggestion) => {
          if (!suggestion.id || !suggestion.attributes) {
            return null;
          }
          const user: User = {
            id: suggestion.id.substring(suggestion.id.indexOf('-') + 1),
            email: suggestion.attributes.email || '',
            name: `${suggestion.attributes.firstName || ''} ${
              suggestion.attributes.lastName || ''
            }`.trim(),
          };
          return user;
        })
        .filter(Boolean) as User[];

      return transformToSelectItems(users);
    },
    [transformToSelectItems]
  );

  const selectedItems = useMemo(
    () => transformToSelectItems(value),
    [value, transformToSelectItems]
  );

  return (
    <SearchSelect<User>
      {...selectProps}
      value={selectedItems}
      search={search}
    />
  );
};

export { UserSearchSelect };
