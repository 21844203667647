import { SearchField } from 'componentsNew';
import { useEffect, useState } from 'react';
import { translations } from 'translations';

const elementId = 'job-search';

type LearningSearchProps = {
  activeSearch: string;
  onSubmit: (value: string) => void;
};

const LearningSearch = ({ activeSearch, onSubmit }: LearningSearchProps) => {
  const [value, setValue] = useState<string>('');

  useEffect(() => {
    setValue(activeSearch);
  }, [activeSearch]);

  return (
    <SearchField
      id={elementId}
      value={value}
      placeholder={translations.jobsSearchPlaceholder}
      sx={{ width: { xs: '100%', md: '20rem' } }}
      onChange={setValue}
      onSubmit={() => {
        if (value !== activeSearch) {
          onSubmit(value);
        }
      }}
      onClear={() => {
        setValue('');
        onSubmit('');
      }}
    />
  );
};

export { LearningSearch };
