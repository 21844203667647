import { getProfile, getProfileSettings } from 'api/organization';
import { Component, createContext, useContext } from 'react';
import { GAitemNotFound } from 'utils/analytics';
import { msalGetActiveAccount } from 'utils/auth';
import { mapRelationships } from 'utils/misc/mapRelationships';

const UserContext = createContext();
const { Consumer: UserConsumer, Provider } = UserContext;

class UserProvider extends Component {
  constructor(props) {
    super(props);

    const account = msalGetActiveAccount();

    const givenName = account.idTokenClaims.given_name || '';
    const familyName = account.idTokenClaims.family_name || '';

    this.state = {
      userId: account.idTokenClaims.oid,
      roles: account.idTokenClaims.roles || [],
      displayName: `${givenName} ${familyName}`,
      initials: `${givenName[0] || ''}${familyName[0] || ''}`,
      firstName: givenName,
      lastName: familyName,
      isLoading: true,
      isProfileError: false,
      profileUpdated: false,
      isAdmin: account.idTokenClaims.roles
        ? account.idTokenClaims.roles.includes('AvenueAdmin')
        : false,
      isSuperUser: account.idTokenClaims.roles
        ? account.idTokenClaims.roles.includes('AvenueSuperuser')
        : false,
      isGlobalEditor: account.idTokenClaims.roles
        ? account.idTokenClaims.roles.includes('AvenueGlobalEditor')
        : false,
      isBlogEditor: account.idTokenClaims.roles
        ? account.idTokenClaims.roles.includes('AvenueBlogEditor')
        : false,
      blockedUser: account.idTokenClaims.roles
        ? account.idTokenClaims.roles.includes('AvenueUnauthorizedUser')
        : false,
      isNotificationUser: account.idTokenClaims.roles
        ? account.idTokenClaims.roles.includes('AvenueNotificationEditor')
        : false,
      isGlobalOnlyUser: account.idTokenClaims.roles
        ? account.idTokenClaims.roles.includes('AvenueGlobalOnlyUser')
        : false,
      findPagination: null,
      navigationKey: 0,
      subscribesToInsightsDatabase: false,
      showSurvey: true,
    };
  }

  componentDidMount() {
    this._isMounted = true;
    this.fetch();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  fetch = async () => {
    let user = {};
    try {
      const [profile, settings] = await Promise.all([
        getProfile(),
        getProfileSettings(),
      ]);

      user = { ...profile.data.data.attributes };

      if (settings.data.data) {
        const mappedProfile = mapRelationships(
          settings.data.data.relationships,
          settings.data.included
        );
        user.consents = mappedProfile.consents;

        user.subscribesToInsightsDatabase =
          settings.data?.data?.attributes?.subscribesToInsightsDatabase;

        if (mappedProfile.division) {
          user.divisionName = mappedProfile.division.name;
          user.divisionId = mappedProfile.division.id;

          // If division in profile settings is different from division in AD,
          // do not use country and segment from AD
          if (
            mappedProfile.divisionName !==
            profile.data.data.attributes.divisionName
          ) {
            user.segmentName = null;
            user.segmentId = null;
            user.countryName = null;
            user.countryId = null;
          }
        }

        if (mappedProfile.country) {
          user.countryName = mappedProfile.country.name;
          user.countryId = mappedProfile.country.id;
        }
        if (mappedProfile.segment) {
          user.segmentName = mappedProfile.segment.name;
          user.segmentId = mappedProfile.segment.id;
        }
        if (mappedProfile.region) {
          user.regionName = mappedProfile.region.name;
          user.regionId = mappedProfile.region.id;
        }
        if (mappedProfile.department) {
          user.departmentName = mappedProfile.department.name;
          user.departmentId = mappedProfile.department.id;
        }
        if (mappedProfile.site) {
          user.siteName = mappedProfile.site.name;
          user.siteId = mappedProfile.site.id;
        }
      }
    } catch (e) {
      if (e.response && e.response.status === 404) {
        user.nouser = true;
      }
      user.isProfileError = true;
      GAitemNotFound(e.status, e.message, 'person');
    } finally {
      this.setState({
        ...user,
        isLoading: false,
      });
    }
  };

  updateUser = (obj) => {
    this.setState(obj);
  };

  setShowSurvey = (bool) => {
    this.setState({ ...this.state, showSurvey: bool });
  };

  setFindPagination = (p) => this.setState({ findPagination: p });

  refreshNavigation = () => {
    this.setState({
      navigationKey: this.state.navigationKey + 1,
    });
  };

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          fetchUser: this.fetch,
          updateUser: this.updateUser,
          setFindPagination: this.setFindPagination,
          refreshNavigation: this.refreshNavigation,
          setShowSurvey: this.setShowSurvey,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export const useUser = () => {
  const context = useContext(UserContext);
  if (!context) {
    throw new Error('UserContext is not defined');
  }
  return context;
};

export { UserContext };
export default UserProvider;
export { UserConsumer };
