import Grid from '@mui/material/Unstable_Grid2';
import * as cmsInnovationApi from 'api/cms-innovation';
import { InnovationTrainingArticle } from 'api/cms-innovation/types';
import { InnovationRouteEnum } from 'enums';
import { Page } from 'layout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { translations } from 'translations';

import { ArticleHeader } from '../common/ArticlePage/ArticleHeader';

const TrainingArticle = () => {
  const [isError, setIsError] = useState<boolean>(false);
  const [article, setArticle] = useState<InnovationTrainingArticle | null>(
    null
  );
  const params = useParams<{ id: string }>();

  const documentTitle = useMemo(
    () =>
      article
        ? [
            translations.innovationDocumentTitle,
            translations.learning,
            article.title,
          ]
        : null,
    [article]
  );

  const documentBreadcrumbs = useMemo(
    () =>
      article
        ? [
            {
              text: translations.learning,
              to: InnovationRouteEnum.Learning,
            },
            { text: article.title },
          ]
        : null,
    [article]
  );

  const fetchArticle = useCallback(async (id: string) => {
    try {
      setIsError(false);
      const data = await cmsInnovationApi.getTrainingArticle(id);
      setArticle(data);
    } catch {
      setIsError(true);
    }
  }, []);

  useEffect(() => {
    fetchArticle(params.id);
  }, [fetchArticle, params.id]);

  if (isError) {
    return <div>Unable to find article</div>;
  }

  if (!article) {
    return <div>Loading...</div>;
  }

  return (
    <Page title={documentTitle} breadcrumbs={documentBreadcrumbs} gridContainer>
      <Grid xs={12}>
        <ArticleHeader article={article} />
      </Grid>
      <Grid xs={12} md={8}></Grid>
      <Grid xs={12} md={4}></Grid>
    </Page>
  );
};

export { TrainingArticle };
