import { InnovationLearningItemsRequestParams } from './types';

export const getInnovationLearningItemsQueryString = (
  params: InnovationLearningItemsRequestParams
) => {
  const { page, limit, search, filter } = params;

  let str = '';
  str = str.concat(`?page[limit]=${limit}`);
  str = str.concat(`&page[offset]=${page - 1}`);

  if (filter.types.length) {
    const typesStr = JSON.stringify(filter.types);
    str = str.concat(`&filter[types]=${typesStr}`);
  }
  if (filter.domains.length) {
    const domainsStr = JSON.stringify(filter.domains);
    str = str.concat(`&filter[domain.ids]=${domainsStr}`);
  }
  if (search.length) {
    str = str.concat(`&filter[query]=${search}`);
  }
  return str;
};
