import { StackProps } from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { Filter, FilterIds } from '../helpers';
import { LearningFilterDesktop } from './LearningFilterDesktop';
import { LearningFilterMobile } from './LearningFilterMobile';

export type LearningFilterProps = {
  sx?: StackProps['sx'];
  activeFilter: FilterIds;
  availableFilter: Filter;
  onChange: (value: FilterIds) => void;
};

const LearningFilter = ({
  sx,
  activeFilter,
  availableFilter,
  onChange,
}: LearningFilterProps) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

  if (isDesktop) {
    return (
      <LearningFilterDesktop
        sx={sx}
        activeFilter={activeFilter}
        availableFilter={availableFilter}
        onChange={onChange}
      />
    );
  }

  return (
    <LearningFilterMobile
      sx={sx}
      activeFilter={activeFilter}
      availableFilter={availableFilter}
      onChange={onChange}
    />
  );
};
export { LearningFilter };
