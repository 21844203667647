import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import OutlinedInput from '@mui/material/OutlinedInput';
import RadioGroup from '@mui/material/RadioGroup';
import Switch from '@mui/material/Switch';
import Grid from '@mui/material/Unstable_Grid2';
import { HeroBannerSize } from 'api/cms/HeroBanner/types';
import * as organizationApi from 'api/organization';
import * as organizationTransformers from 'api/organization/transformers';
import { Division } from 'api/organization/types';
import Hero from 'components/Hero';
import { MultiSelect, Radio } from 'componentsNew';
import { SelectItem } from 'componentsNew/Select/SelectMenuItem';
import { AvenueRouteEnum } from 'enums';
import {
  Form,
  FormButtons,
  FormFieldWrapper,
  FormLoading,
  FormStack,
  PageContentCard,
} from 'layout';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, SubmitHandler, useFormContext } from 'react-hook-form';
import { useHistory, useLocation } from 'react-router-dom';
import { translations } from 'translations';
import * as cmsUtils from 'utils/cms';
import * as formUtils from 'utils/form';

import * as helpers from './helpers';
import { HeroBannerFormSkeleton } from './HeroBannerFormSkeleton';
import { HeroBannerPreview } from './HeroBannerPreview';

const elementId = 'hero-banner-form';

export type HeroImage = {
  raw?: {
    file: string;
    blob: Blob;
    height: number;
    width: number;
  };
  uploaded?: string;
  altText?: string;
};

export type FormValues = {
  size: HeroBannerSize;
  title: string;
  description: string;
  linkUrl: string;
  linkLabel: string;
  heroImage: HeroImage;
  publishedDate: Date | null;
  publishedInDivisions: { id: string; name: string }[];
  overrideDivisionalMessage: boolean;
};

type HeroBannerFormProps = {
  beforeSubmit?: () => void | Promise<void>;
  onSubmit: SubmitHandler<FormValues>;
  onDelete?: () => void;
};

export const DEFAULT_VALUES: FormValues = {
  size: HeroBannerSize.Medium,
  title: '',
  description: '',
  linkUrl: '',
  linkLabel: '',
  heroImage: {},
  publishedDate: new Date(),
  publishedInDivisions: [],
  overrideDivisionalMessage: false,
};

const HeroBannerForm = ({
  beforeSubmit,
  onSubmit,
  onDelete,
}: HeroBannerFormProps) => {
  const history = useHistory();
  const location = useLocation();
  const methods = useFormContext<FormValues>();

  const {
    control,
    formState: { errors, isLoading, isSubmitting },
    handleSubmit,
    watch,
  } = methods;

  const [divisions, setDivisions] = useState<Division[] | null>(null);

  const watchSize = watch('size');
  const watchPublishedInDivisions = watch('publishedInDivisions');

  const onCancel = useCallback(() => {
    const canGoBack = Boolean(location.key);
    if (canGoBack) {
      history.goBack();
    } else {
      history.push(AvenueRouteEnum.Home);
    }
  }, [location, history]);

  const fetchDivisions = useCallback(async () => {
    try {
      const response = await organizationApi.getDivisions();
      const divisions = organizationTransformers.divisionsResponseToDivisions(
        response,
        true
      );
      setDivisions(divisions);
    } catch {}
  }, []);

  const publishedInDivisionsSelectItems = useMemo(() => {
    const globalDivisionSelectItem: SelectItem = {
      name: helpers.GLOBAL_DIVISION.name,
      value: helpers.GLOBAL_DIVISION.id,
      disabled: false,
    };
    if (!divisions) {
      return [globalDivisionSelectItem];
    }
    const isGlobalDivisionSelected = watchPublishedInDivisions.some(
      (item) => item.id === helpers.GLOBAL_DIVISION.id
    );
    const isDivisionSelected =
      !isGlobalDivisionSelected && watchPublishedInDivisions.length > 0;

    const divisionSelectItems: SelectItem[] = divisions.map((division) => ({
      name: division.name,
      value: division.id,
      disabled: isGlobalDivisionSelected,
    }));

    if (isDivisionSelected) {
      globalDivisionSelectItem.disabled = true;
    }
    return [globalDivisionSelectItem, ...divisionSelectItems];
  }, [divisions, watchPublishedInDivisions]);

  useEffect(() => {
    fetchDivisions();
  }, [fetchDivisions]);

  if (isLoading) {
    return (
      <Form id={elementId}>
        <Grid container>
          <Grid xs={12} lg={8}>
            <PageContentCard variant="elevated">
              <HeroBannerFormSkeleton />
            </PageContentCard>
          </Grid>
        </Grid>
      </Form>
    );
  }

  return (
    <Form
      id={elementId}
      onSubmit={async (e) => {
        beforeSubmit && (await beforeSubmit());
        handleSubmit(onSubmit)(e);
      }}
    >
      <Grid container>
        <Grid xs={12} lg={8}>
          <PageContentCard variant="elevated">
            <FormLoading isLoading={isSubmitting} scroll />
            <FormStack>
              <Controller
                name="size"
                control={control}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-size`}
                      label={translations.formLabelSize}
                      error={errors.size?.message}
                    >
                      <RadioGroup value={field.value} onChange={field.onChange}>
                        <FormControlLabel
                          label={translations.formLabelSizeMedium}
                          value={HeroBannerSize.Medium}
                          control={<Radio id={`${elementId}-size-medium`} />}
                        />
                        <FormControlLabel
                          label={translations.formLabelSizeLarge}
                          value={HeroBannerSize.Large}
                          control={<Radio id={`${elementId}-size-large`} />}
                        />
                      </RadioGroup>
                    </FormFieldWrapper>
                  );
                }}
              />
              <Controller
                name="title"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formUtils.getErrorMessage('required', {
                      displayName: translations.formLabelTitle,
                    }),
                  },
                  maxLength: {
                    value: 60,
                    message: formUtils.getErrorMessage('maxLength', {
                      displayName: translations.formLabelTitle,
                      value: 60,
                    }),
                  },
                }}
                render={({ field: { ref, ...field } }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-title`}
                      label={translations.formLabelTitle}
                      error={errors.title?.message}
                    >
                      <OutlinedInput {...field} size="small" inputRef={ref} />
                    </FormFieldWrapper>
                  );
                }}
              />
              <Controller
                name="description"
                control={control}
                rules={{
                  maxLength: {
                    value: 270,
                    message: formUtils.getErrorMessage('maxLength', {
                      displayName: translations.formLabelDescription,
                      value: 270,
                    }),
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormFieldWrapper
                    id={`${elementId}-description`}
                    label={translations.formLabelDescription}
                    error={errors.description?.message}
                  >
                    <OutlinedInput {...field} size="small" inputRef={ref} />
                  </FormFieldWrapper>
                )}
              />
              <Controller
                name="linkLabel"
                control={control}
                render={({ field: { ref, ...field } }) => (
                  <FormFieldWrapper
                    id={`${elementId}-linkLabel`}
                    label={translations.formLabelButtonText}
                    error={errors.linkLabel?.message}
                  >
                    <OutlinedInput {...field} size="small" inputRef={ref} />
                  </FormFieldWrapper>
                )}
              />
              <Controller
                name="linkUrl"
                control={control}
                rules={{
                  validate: (value) =>
                    formUtils.customValidators.url(value) ||
                    formUtils.getErrorMessage('url', {
                      displayName: translations.formLabelButtonLink,
                    }),
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormFieldWrapper
                    id={`${elementId}-linkUrl`}
                    label={translations.formLabelButtonLink}
                    error={errors.linkUrl?.message}
                  >
                    <OutlinedInput {...field} size="small" inputRef={ref} />
                  </FormFieldWrapper>
                )}
              />
              {watchSize === HeroBannerSize.Large && (
                <Controller
                  name="heroImage"
                  control={control}
                  render={({ field }) => (
                    <FormFieldWrapper
                      id={`${elementId}-heroImage`}
                      label={translations.formLabelImage}
                      error={errors.heroImage?.message}
                      sx={{
                        '.editable__hero': { marginBottom: '0 !important' },
                      }}
                    >
                      <Hero
                        useVideo={false}
                        useAltText={true}
                        aspectRatio={3 / 2}
                        displayType="feed"
                        minWidth={480}
                        heroImage={cmsUtils.getImageSrc(field.value.uploaded)}
                        heroAltText={field.value.altText}
                        onChange={(value: any) => {
                          if (!value?.heroImage) {
                            field.onChange({});
                            return;
                          }
                          const { altText, blob, file, height, width } =
                            value.heroImage;
                          const newHeroImage: HeroImage = {
                            uploaded: field.value.uploaded,
                            altText: altText,
                            raw: { blob, file, height, width },
                          };
                          field.onChange(newHeroImage);
                        }}
                      />
                    </FormFieldWrapper>
                  )}
                />
              )}
              <Controller
                name="publishedInDivisions"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: formUtils.getErrorMessage('required', {
                      displayName: translations.formLabelPublishTarget,
                    }),
                  },
                }}
                render={({ field: { ref, ...field } }) => (
                  <FormFieldWrapper
                    id={`${elementId}-publishedInDivisions`}
                    label={translations.formLabelPublishTarget}
                    error={errors.publishedInDivisions?.message}
                    warning={
                      divisions && divisions.length < 2
                        ? translations.divisionGetError
                        : undefined
                    }
                  >
                    <MultiSelect
                      items={publishedInDivisionsSelectItems}
                      placeholder={translations.formPlaceholderDivisionOrGlobal}
                      value={field.value.map((division) => ({
                        name: division.name,
                        value: division.id,
                      }))}
                      onBlur={field.onBlur}
                      onChange={(value) => {
                        const isGlobalDivisionSelected = value.some(
                          (item) => item.value === helpers.GLOBAL_DIVISION.id
                        );
                        if (isGlobalDivisionSelected) {
                          field.onChange([helpers.GLOBAL_DIVISION]);
                          return;
                        }
                        field.onChange(
                          value.map((item) => ({
                            id: item.value,
                            name: item.name,
                          }))
                        );
                      }}
                    />
                  </FormFieldWrapper>
                )}
              />
              <Controller
                name="overrideDivisionalMessage"
                control={control}
                render={({ field: { ref, value, ...field } }) => {
                  return (
                    <FormFieldWrapper
                      id={`${elementId}-overrideDivisionalMessage`}
                      label={translations.formLabelOverrideDivisionalMessage}
                      labelPlacement="right"
                      error={errors.size?.message}
                    >
                      <Switch {...field} checked={value} inputRef={ref} />
                    </FormFieldWrapper>
                  );
                }}
              />

              <Divider
                sx={(theme) => ({ margin: `${theme.spacing('sm')} 0` })}
              />
              <HeroBannerPreview />

              <FormButtons>
                {onDelete && (
                  <Button variant="outlined" onClick={onDelete}>
                    {translations.delete}
                  </Button>
                )}
                <Button
                  variant="text"
                  onClick={onCancel}
                  sx={{ marginLeft: { xs: 0, sm: 'auto' } }}
                >
                  {translations.cancel}
                </Button>
                <Button variant="contained" type="submit">
                  {translations.publish}
                </Button>
              </FormButtons>
            </FormStack>
          </PageContentCard>
        </Grid>
      </Grid>
    </Form>
  );
};

export { HeroBannerForm };
