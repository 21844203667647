export enum InnovationLearningListingItemTypeEnum {
  CaseStudy = 'aadw:innovation-case-study',
  Podcast = 'aadw:innovation-podcast',
  Training = 'aadw:innovation-training',
  Webinar = 'aadw:innovation-webinar',
}

export type InnovationLearningListingItem = {
  id: string;
  type: InnovationLearningListingItemTypeEnum;
  title: string;
  preamble?: string;
  imageUrl?: string;
  imageAltText?: string;
  lastModifiedDate: string;
  domains: { id: string; title: string }[];
};

export type InnovationLearningItemsRequestParams = {
  page: number;
  limit: number;
  search: string;
  filter: {
    types: string[];
    domains: string[];
  };
};
