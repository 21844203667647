import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { InnovationTrainingArticle } from 'api/cms-innovation/types';
import backgroundImage from 'assets/images/innovation-article-header-background-learning.png';
import { Chip } from 'componentsNew';
import { useBreakpoints } from 'hooks';
import { translations } from 'translations';

const elementId = 'training-article-header';

type ArticleHeaderProps = {
  article: InnovationTrainingArticle;
};
const ArticleHeader = ({ article }: ArticleHeaderProps) => {
  const { isMobile } = useBreakpoints();

  return (
    <Stack
      sx={(theme) => ({
        alignItems: 'baseline',
        gap: theme.spacing('sm'),
        borderRadius: theme.border.radius.xxl,
        background: `linear-gradient(rgba(255,255,255,.5), rgba(255,255,255,.5)), url(${backgroundImage})`,
        padding: {
          xs: `${theme.spacing('md')}`,
          md: `${theme.spacing('lg')} ${theme.spacing('xl')}`,
        },
      })}
    >
      <Chip
        id={`${elementId}-type`}
        variant="outlined"
        size="small"
        label={translations.innovationTypeTraining}
        sx={(theme) => ({
          '&.MuiChip-outlined.MuiChip-outlinedDefault': {
            fontWeight: theme.typography.fontWeightBold,
            borderColor: theme.palette.secondary2[900],
            color: theme.palette.secondary2[900],
            backgroundColor: 'unset',
          },
        })}
      />
      <Typography
        id={`${elementId}-title`}
        variant={isMobile ? 'h2' : 'display4'}
        component="h1"
        sx={(theme) => ({
          fontWeight: theme.typography.fontWeightBold,
          color: theme.palette.secondary2[900],
        })}
      >
        {article.title}
      </Typography>
      <Typography
        id={`${elementId}-preamble`}
        variant={isMobile ? 'body1' : 'h4'}
        component="p"
        sx={(theme) => ({
          fontWeight: 'unset',
          color: theme.palette.secondary2[900],
        })}
      >
        {article.preamble}
      </Typography>
      {article.domains.length > 0 && (
        <Stack
          id={`${elementId}-domains`}
          sx={(theme) => ({
            flexDirection: 'row',
            gap: theme.spacing('xxs'),
            flexWrap: 'wrap',
          })}
        >
          {article.domains.map((domain, index) => (
            <Chip
              key={`${elementId}-domains-${index}`}
              id={`${elementId}-domains-${index}`}
              size="small"
              color="default"
              variant="outlined"
              label={domain.title}
              sx={{ maxWidth: { xs: '10rem', md: '20rem' } }}
            />
          ))}
        </Stack>
      )}
    </Stack>
  );
};

export { ArticleHeader };
