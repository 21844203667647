import List, { ListProps } from '@mui/material/List';
import Typography from '@mui/material/Typography';
import { InnovationLearningListingItem } from 'api/find/types';
import { translations } from 'translations';

import { ArticleListItem } from './ArticleListItem';

type ArticleListProps = {
  elementId: string;
  sx?: ListProps['sx'];
  articles: InnovationLearningListingItem[];
  onArticleClick?: (article: InnovationLearningListingItem) => void;
};

const ArticleList = ({
  elementId,
  sx,
  articles,
  onArticleClick,
}: ArticleListProps) => {
  return (
    <List
      id={elementId}
      sx={[
        (theme) => ({
          width: '100%',
          display: 'flex',
          flexDirection: 'column',
          gap: theme.spacing('xs'),
        }),
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
    >
      <Typography variant="srOnly" component="h2">
        {translations.articles}
      </Typography>
      {articles.map((article, index) => (
        <ArticleListItem
          key={`${elementId}-item-${index}`}
          elementId={`${elementId}-item-${index}`}
          article={article}
          onClick={onArticleClick}
        />
      ))}
    </List>
  );
};
export { ArticleList };
