import Autocomplete from '@mui/material/Autocomplete';
import { Theme } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import { TextFieldProps as MuiTextFieldProps } from '@mui/material/TextField';
import { SxProps } from '@mui/system/styleFunctionSx';
import { Icon } from 'componentsNew/Icon/Icon';
import { useMemo } from 'react';
import { translations } from 'translations';

import { SelectItem, SelectMenuItem } from './SelectMenuItem';

export type SelectBaseProps<T> = {
  id?: string;
  items: SelectItem<T>[];
  error?: boolean;
  fullWidth?: boolean;
  placeholder?: string;
  noItemsText?: string;
  disabled?: boolean;
  disableItems?: boolean;
  disablePopupIcon?: boolean;
  disableCheckIcon?: boolean;
  disableCloseOnSelect?: boolean;
  filterSelectedItems?: boolean;
  openOnFocus?: boolean;
  inputProps?: MuiTextFieldProps['inputProps'];
  inputRef?: MuiTextFieldProps['inputRef'];
  sx?: SxProps<Theme>;
  onInputChange?: (value: string) => void;
  onBlur?: () => void;
};

export type SingleSelectProps<T = {}> = {
  value: string | SelectItem<T> | null;
  onChange: (value: SelectItem<T> | null) => void;
} & SelectBaseProps<T>;

const SingleSelect = <T,>({
  id,
  value,
  items,
  error,
  fullWidth,
  placeholder,
  noItemsText,
  disabled = false,
  disableItems = false,
  disablePopupIcon = false,
  disableCheckIcon = false,
  disableCloseOnSelect = false,
  filterSelectedItems = false,
  openOnFocus = true,
  inputProps,
  inputRef,
  sx,
  onInputChange,
  onChange,
  onBlur,
}: SingleSelectProps<T>) => {
  const selectValue = useMemo(() => {
    if (!value) {
      return null;
    }
    if (typeof value === 'object') {
      return value;
    }
    const item = items.find((item) => item.value === value);
    return item || null;
  }, [items, value]);

  return (
    <Autocomplete
      id={id}
      sx={sx}
      autoHighlight
      disablePortal
      size="small"
      options={items}
      value={selectValue}
      fullWidth={fullWidth}
      disabled={disabled}
      disableCloseOnSelect={disableCloseOnSelect}
      openOnFocus={openOnFocus}
      popupIcon={
        disabled || disablePopupIcon ? null : (
          <Icon type="chevronDown" color="secondary" size={20} />
        )
      }
      clearIcon={<Icon type="xMark" color="secondary" size={16} />}
      openText={translations.open}
      clearText={translations.clear}
      noOptionsText={noItemsText}
      getOptionLabel={(option) => option.name}
      getOptionKey={(option) => `${option.name}-${option.value}`}
      getOptionDisabled={(option) => option.disabled || disableItems}
      filterSelectedOptions={filterSelectedItems}
      isOptionEqualToValue={(option, selected) =>
        option.value === selected.value
      }
      slotProps={{
        popper: {
          sx: {
            '.MuiAutocomplete-noOptions': {
              display: noItemsText ? 'block' : 'none',
            },
          },
        },
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          inputRef={inputRef}
          inputProps={{
            ...params.inputProps,
            ...inputProps,
            disabled: disabled,
          }}
          placeholder={selectValue ? undefined : placeholder}
          disabled={disabled}
          error={error}
        />
      )}
      renderOption={({ key, ...props }, option) => (
        <SelectMenuItem
          {...props}
          key={key}
          item={option}
          disableCheckIcon={disableCheckIcon}
        />
      )}
      onInputChange={(_e, value) => {
        onInputChange && onInputChange(value);
      }}
      onChange={(_e, value) => {
        onChange(value);
      }}
      onBlur={() => {
        onBlur && onBlur();
      }}
    />
  );
};

export { SingleSelect };
