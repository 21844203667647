import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import { useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import * as organizationApi from 'api/organization';
import * as organizationTransformers from 'api/organization/transformers';
import {
  Department,
  DivisionWithSegmentsAndCountries,
} from 'api/organization/types';
import { useUser } from 'components/Context/User';
import {
  SingleSelect,
  SiteSearchSelect,
  TypographyWithLink,
} from 'componentsNew';
import { useOrganizationData, useSnackbar } from 'context';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { translations } from 'translations';
import { GAonClickProfilePreferencesSave } from 'utils/analytics';
import { getCleanSegmentName } from 'utils/misc/transformSegmentName';

import { InfoBox } from '../InfoBox';
import {
  getCustomSegmentLabel,
  PREFERENCES_FORM_FIELD_NAMES,
  PREFERENCES_FORM_FIELD_SETTINGS,
  PreferencesForm,
  PreferencesFormFieldName,
  PreferencesFormValidation,
} from './helpers';
import * as helpers from './helpers';
import { PreferencesFormFieldWrapper } from './PreferencesFormFieldWrapper';
import { PreferencesSkeleton } from './PreferencesSkeleton';
import * as validationHelpers from './validation';

const elementId = 'profile-preferences';

const Preferences = () => {
  const [formData, setFormData] = useState<{
    divisionsWithSegmentsAndCountries: DivisionWithSegmentsAndCountries[];
    departments: Department[];
  } | null>(null);

  const [formInput, setFormInput] = useState<PreferencesForm>(
    helpers.getInitialFormInput()
  );
  const [formValidation, setFormValidation] =
    useState<PreferencesFormValidation>(helpers.getInitialFormValidation());

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const user = useUser();
  const { getDivisionsTree } = useOrganizationData();
  const theme = useTheme();
  const { showSnackbar } = useSnackbar();

  const isAllowedToChangeDivision = useMemo(
    () => user.roles && user.roles.includes('AvenueCrossDivisionalUser'),
    [user.roles]
  );

  const isSaveButtonVisible = useMemo(
    () =>
      formData &&
      !isLoading &&
      !user.isLoading &&
      helpers.didUserMakeAnyChanges(formInput, user) &&
      !PREFERENCES_FORM_FIELD_NAMES.some(
        (fieldName) => !formValidation[fieldName].valid
      ),
    [formData, formInput, formValidation, isLoading, user]
  );

  const divisionItems = useMemo(() => {
    if (!formData?.divisionsWithSegmentsAndCountries.length) {
      return [{ name: user.divisionName, value: user.divisionId }];
    }
    return formData.divisionsWithSegmentsAndCountries.map((division) => ({
      name: division.name,
      value: division.id,
    }));
  }, [formData, user.divisionId, user.divisionName]);

  const countryItems = useMemo(() => {
    if (!formData?.divisionsWithSegmentsAndCountries.length) {
      return [{ name: user.countryName, value: user.countryId }];
    }

    const validCountries = formData.divisionsWithSegmentsAndCountries.find(
      (division) => division.id === formInput.division?.id
    )?.countries;

    if (!validCountries) {
      return [{ name: user.countryName, value: user.countryId }];
    }
    return validCountries.map((country) => ({
      name: country.name,
      value: country.id,
    }));
  }, [formData, formInput.division, user.countryId, user.countryName]);

  const departmentItems = useMemo(() => {
    if (!formData?.departments.length) {
      return [{ name: user.departmentName, value: user.departmentId }];
    }
    return formData.departments.map((department) => ({
      name: department.name,
      value: department.id,
    }));
  }, [formData, user.departmentId, user.departmentName]);

  const segmentItems = useMemo(() => {
    const cleanUserSegmentName = getCleanSegmentName(
      user.segmentName,
      user.segmentId
    );

    if (!formData?.divisionsWithSegmentsAndCountries.length) {
      return [{ name: cleanUserSegmentName, value: user.segmentValue }];
    }

    const validSegments = formData.divisionsWithSegmentsAndCountries.find(
      (division) => division.id === formInput.division?.id
    )?.segments;

    if (!validSegments) {
      return [{ name: cleanUserSegmentName, value: user.segmentValue }];
    }

    return validSegments.map((segment) => ({
      name: getCleanSegmentName(segment.name, segment.id),
      value: segment.id,
    }));
  }, [
    formData?.divisionsWithSegmentsAndCountries,
    formInput.division?.id,
    user.segmentId,
    user.segmentName,
    user.segmentValue,
  ]);

  const updateProfile = useCallback(async () => {
    setIsLoading(true);
    const payload =
      helpers.transformFormInputToUpdateProfileSettingsPayload(formInput);
    try {
      await organizationApi.updateProfileSettings(
        user.userId,
        payload,
        user.subscribesToInsightsDatabase
      );
    } catch {
      showSnackbar({
        type: 'error',
        text: translations.profileUpdateError,
      });
      setIsLoading(false);
      return;
    }
    try {
      await user.fetchUser();
    } catch {
    } finally {
      showSnackbar({
        type: 'success',
        text: translations.profileUpdateSuccess,
      });
      setIsLoading(false);
    }
  }, [formInput, showSnackbar, user]);

  const updateFieldValidation = useCallback(
    (
      fieldName: PreferencesFormFieldName,
      value: { id: string; name: string } | null
    ) => {
      const validationResult = validationHelpers.validateField(
        value,
        PREFERENCES_FORM_FIELD_SETTINGS[fieldName].validators
      );
      setFormValidation({ ...formValidation, [fieldName]: validationResult });
    },
    [formValidation]
  );

  const updateFormValidation = useCallback((formInput: PreferencesForm) => {
    let isFormValid = true;
    const newFormValidation: PreferencesFormValidation =
      helpers.getInitialFormValidation();

    PREFERENCES_FORM_FIELD_NAMES.forEach((fieldName) => {
      const validationResult = validationHelpers.validateField(
        formInput[fieldName],
        PREFERENCES_FORM_FIELD_SETTINGS[fieldName].validators
      );
      if (!validationResult.valid) {
        isFormValid = false;
      }
      newFormValidation[fieldName] = validationResult;
    });
    setFormValidation(newFormValidation);
    return isFormValid;
  }, []);

  const onChange = useCallback(
    (
      fieldName: PreferencesFormFieldName,
      value: { id: string; name: string } | null
    ) => {
      if (!formData) return;

      switch (fieldName) {
        case 'division':
          const isCountryStillValid =
            value !== null &&
            formInput.country !== null &&
            helpers.isCountryValidForDivision(
              formInput.country.id,
              value.id,
              formData.divisionsWithSegmentsAndCountries
            );

          setFormInput({
            ...formInput,
            division: value,
            ...(!isCountryStillValid && { country: null }),
            ...{ segment: null },
          });

          if (formValidation.division.valid) return;
          updateFieldValidation('division', value);
          break;
        default:
          setFormInput({ ...formInput, [fieldName]: value });
          if (formValidation[fieldName].valid) return;
          updateFieldValidation(fieldName, value);
      }
    },
    [formData, formInput, formValidation, updateFieldValidation]
  );

  const onSubmit = useCallback(() => {
    const isFormValid = updateFormValidation(formInput);
    if (!isFormValid) return;
    GAonClickProfilePreferencesSave();
    updateProfile();
  }, [formInput, updateFormValidation, updateProfile]);

  const fetchPreferencesFormData = useCallback(async () => {
    let isError = false;

    const _formData: {
      divisionsWithSegmentsAndCountries: DivisionWithSegmentsAndCountries[];
      departments: Department[];
    } = {
      divisionsWithSegmentsAndCountries: [],
      departments: [],
    };

    const divisionTree = await getDivisionsTree();
    if (divisionTree) {
      _formData.divisionsWithSegmentsAndCountries = divisionTree.divisions;
    } else {
      isError = true;
    }
    try {
      const response = await organizationApi.getDepartments();
      const transformed =
        organizationTransformers.departmentsResponseToDepartments(response);
      _formData.departments = transformed;
    } catch {
      isError = true;
    }
    if (isError) {
      showSnackbar({
        type: 'error',
        text: translations.profileFetchFormDataError,
      });
    }
    setFormData(_formData);
  }, [getDivisionsTree, showSnackbar]);

  useEffect(() => {
    fetchPreferencesFormData();
  }, [fetchPreferencesFormData]);

  useEffect(() => {
    if (user.isLoading) return;
    setFormInput(helpers.getInitialFormInput(user));
  }, [user]);

  if (user.isLoading || !formData) {
    return <PreferencesSkeleton />;
  }

  return (
    <Box
      sx={(theme) => ({
        padding: theme.spacing('md'),
        backgroundColor: theme.colors.surface.primary,
      })}
    >
      <Grid
        container
        id={elementId}
        spacing={{ xs: theme.spacing('lg'), md: theme.spacing('sm') }}
      >
        <Grid xs={12} md={7}>
          <Stack sx={(theme) => ({ rowGap: theme.spacing('sm') })}>
            <PreferencesFormFieldWrapper
              id={`${elementId}-division`}
              label={translations.division}
              error={formValidation.division.messages}
              info={
                !formInput.division && !isAllowedToChangeDivision
                  ? [translations.profilePreferencesDivisionInfo]
                  : []
              }
            >
              <SingleSelect
                items={divisionItems}
                placeholder={`${translations.select} ${translations.division}`}
                disabled={isLoading || !isAllowedToChangeDivision}
                sx={(theme) => ({
                  '.MuiInputBase-root:not(.Mui-disabled)': {
                    backgroundColor: theme.colors.surface.secondary,
                  },
                })}
                value={
                  formInput.division && {
                    name: formInput.division.name,
                    value: formInput.division.id,
                  }
                }
                onChange={(item) =>
                  onChange(
                    'division',
                    item ? { id: item.value, name: item.name } : null
                  )
                }
              />
            </PreferencesFormFieldWrapper>

            <PreferencesFormFieldWrapper
              id={`${elementId}-segment`}
              label={getCustomSegmentLabel(formInput.division?.id)}
              error={formValidation.segment.messages}
            >
              <SingleSelect
                items={segmentItems}
                placeholder={`${translations.select} ${getCustomSegmentLabel(
                  formInput.division?.id
                )}`}
                disabled={
                  isLoading || !segmentItems.length || !formInput.division
                }
                sx={(theme) => ({
                  '.MuiInputBase-root:not(.Mui-disabled)': {
                    backgroundColor: theme.colors.surface.secondary,
                  },
                })}
                value={
                  formInput.segment && {
                    name: formInput.segment.name,
                    value: formInput.segment.id,
                  }
                }
                onChange={(item) =>
                  onChange(
                    'segment',
                    item ? { id: item.value, name: item.name } : null
                  )
                }
              />
            </PreferencesFormFieldWrapper>
            <PreferencesFormFieldWrapper
              id={`${elementId}-country`}
              label={translations.country}
              error={formValidation.country.messages}
              info={
                !formInput.division
                  ? [translations.profilePreferencesCountryInfo]
                  : []
              }
            >
              <SingleSelect
                items={countryItems}
                placeholder={`${translations.select} ${translations.country}`}
                disabled={isLoading || !formInput.division}
                sx={(theme) => ({
                  '.MuiInputBase-root:not(.Mui-disabled)': {
                    backgroundColor: theme.colors.surface.secondary,
                  },
                })}
                value={
                  formInput.country && {
                    name: formInput.country.name,
                    value: formInput.country.id,
                  }
                }
                onChange={(item) =>
                  onChange(
                    'country',
                    item ? { id: item.value, name: item.name } : null
                  )
                }
              />
            </PreferencesFormFieldWrapper>
            <PreferencesFormFieldWrapper
              id={`${elementId}-department`}
              label={translations.department}
              error={formValidation.department.messages}
            >
              <SingleSelect
                items={departmentItems}
                placeholder={`${translations.select} ${translations.department}`}
                disabled={isLoading}
                sx={(theme) => ({
                  '.MuiInputBase-root:not(.Mui-disabled)': {
                    backgroundColor: theme.colors.surface.secondary,
                  },
                })}
                value={
                  formInput.department && {
                    name: formInput.department.name,
                    value: formInput.department.id,
                  }
                }
                onChange={(item) =>
                  onChange(
                    'department',
                    item ? { id: item.value, name: item.name } : null
                  )
                }
              />
            </PreferencesFormFieldWrapper>
            <PreferencesFormFieldWrapper
              id={`${elementId}-site`}
              label={translations.site}
              error={formValidation.site.messages}
            >
              <SiteSearchSelect
                placeholder={`${translations.search} ${translations.site}`}
                disabled={isLoading}
                sx={(theme) => ({
                  '.MuiInputBase-root:not(.Mui-disabled)': {
                    backgroundColor: theme.colors.surface.secondary,
                  },
                })}
                value={
                  formInput.site
                    ? [
                        {
                          name: formInput.site.name,
                          value: formInput.site.id,
                        },
                      ]
                    : []
                }
                onChange={(items) =>
                  onChange(
                    'site',
                    items.length
                      ? { id: items[0].value, name: items[0].name }
                      : null
                  )
                }
              />
            </PreferencesFormFieldWrapper>
            {isSaveButtonVisible && (
              <Button
                id={`${elementId}-save-button`}
                variant="contained"
                sx={(theme) => ({
                  alignSelf: 'baseline',
                  marginTop: theme.spacing('md'),
                })}
                onClick={onSubmit}
              >
                {translations.save}
              </Button>
            )}
          </Stack>
        </Grid>
        <Grid xs={12} md={5}>
          <InfoBox ariaLabel={translations.information}>
            <Typography>{translations.profilePreferencesInfo1}</Typography>
            <Typography>{translations.profilePreferencesInfo2}</Typography>
            <Typography>{translations.profilePreferencesInfo3}</Typography>
            <TypographyWithLink
              href="https://adx.assaabloy.net/SelfService#/MyProperties"
              target="_blank"
            >
              {translations.profilePreferencesInfo4}
            </TypographyWithLink>
          </InfoBox>
        </Grid>
      </Grid>
    </Box>
  );
};

export { Preferences };
