import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import notFoundImage from 'assets/images/not-found.png';

type LearningEmptyProps = {
  children: React.ReactNode;
};
const LearningEmpty = ({ children }: LearningEmptyProps) => (
  <Stack
    sx={(theme) => ({
      width: '100%',
      alignItems: 'center',
      textAlign: 'center',
      background: theme.colors.surface.primary,
      padding: theme.spacing('sm'),
      paddingBottom: theme.spacing('lg'),
    })}
  >
    <Box component="img" src={notFoundImage} maxWidth="20rem" />
    {children}
  </Stack>
);

export { LearningEmpty };
