import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Consumer } from 'components/CreateOrEditArticle';
import { UserSearchSelect } from 'componentsNew';
import { translations } from 'translations';

const ArticleInformationOwner = () => {
  return (
    <Consumer>
      {({ onChangeArticle, updatedArticle }) => {
        return (
          <Stack maxWidth="42.5rem" gap="0.5rem">
            <Typography variant="body1" fontWeight="bold">
              {translations.articleFormInformationOwner1}
            </Typography>
            <Typography variant="body2" marginBottom="0.5rem">
              {translations.articleFormInformationOwner2}
            </Typography>
            <UserSearchSelect
              multiple={false}
              placeholder={translations.searchEmployees}
              value={
                updatedArticle.informationOwner?.oid &&
                updatedArticle.informationOwner?.name
                  ? [
                      {
                        id: updatedArticle.informationOwner.oid,
                        name: updatedArticle.informationOwner.name,
                      },
                    ]
                  : []
              }
              onChange={(selectedUsers) =>
                onChangeArticle({
                  informationOwner:
                    selectedUsers.length > 0
                      ? {
                          oid: selectedUsers[0].id,
                          name: selectedUsers[0].name,
                        }
                      : null,
                })
              }
            />
          </Stack>
        );
      }}
    </Consumer>
  );
};

export { ArticleInformationOwner };
