import Divider from '@mui/material/Divider';
import Stack, { StackProps } from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { AccordionWithCheckboxes } from 'pagesInnovation/common';
import { useCallback } from 'react';
import { translations } from 'translations';

import { Filter, FilterIds, FilterName } from '../helpers';

const elementId = 'learning-filter';

type LearningFilterDesktopProps = {
  sx?: StackProps['sx'];
  activeFilter: FilterIds;
  availableFilter: Filter;
  onChange: (value: FilterIds) => void;
};

const LearningFilterDesktop = ({
  sx,
  activeFilter,
  availableFilter,
  onChange,
}: LearningFilterDesktopProps) => {
  const handleCheckboxChange = useCallback(
    (
      name: FilterName,
      item: { name: string; value: string },
      checked: boolean
    ) => {
      const newIds = checked
        ? [...activeFilter[name], item.value]
        : activeFilter[name].filter((id) => id !== item.value);

      const newFilter = {
        ...activeFilter,
        [name]: newIds,
      };
      onChange(newFilter);
    },
    [activeFilter, onChange]
  );

  return (
    <>
      <Typography variant="srOnly" component="h2">
        {`${translations.filter} ${translations.learning}`}
      </Typography>
      <Stack
        id={elementId}
        divider={<Divider />}
        sx={[
          (theme) => ({
            width: '100%',
            backgroundColor: theme.colors.surface.primary,
            borderRadius: theme.border.radius.md,
            padding: `0 ${theme.spacing('sm')}`,
            height: 'fit-content',
            flexShrink: 0,
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        {availableFilter.types.length > 0 && (
          <AccordionWithCheckboxes
            elementId={`${elementId}-${FilterName.Types}`}
            title={translations.innovationTypes}
            value={activeFilter.types}
            items={availableFilter.types.map((option) => ({
              name: option.name,
              value: option.id,
            }))}
            defaultExpanded
            onChange={(item, checked) =>
              handleCheckboxChange(FilterName.Types, item, checked)
            }
          />
        )}
        {availableFilter.domains.length > 0 && (
          <AccordionWithCheckboxes
            elementId={`${elementId}-${FilterName.Domains}`}
            title={translations.innovationDomains}
            value={activeFilter.domains}
            items={availableFilter.domains.map((option) => ({
              name: option.name,
              value: option.id,
            }))}
            defaultExpanded
            onChange={(item, checked) =>
              handleCheckboxChange(FilterName.Domains, item, checked)
            }
          />
        )}
      </Stack>
    </>
  );
};
export { LearningFilterDesktop };
