import FeedProvider from 'components/Context/Feed';
import { UserConsumer, useUser } from 'components/Context/User';
import MenuContent from 'components/Navigation/MenuContent';
import NoUser from 'components/nouser';
import PageHeader from 'components/PageHeader';
import RedirectAll from 'components/RedirectAll';
import {
  GlobalWebcastProvider,
  HomeBannerProvider,
  MyMomentProvider,
  OrganizationDataProvider,
  useLibraryRoot,
  useRedirects,
} from 'context';
import { AvenueRouteEnum } from 'enums';
import Administration from 'pages/Admin';
import BlogEdit from 'pages/Blog/Edit';
import Blog from 'pages/Blog/View';
import CrossWordCampaign from 'pages/Campaigns/Crossword';
import Feed from 'pages/Feed';
import Find from 'pages/Find';
import Library from 'pages/Library';
import LibraryIdRedirect from 'pages/Library/IdRedirect';
import NewsEdit from 'pages/News/Edit';
import NewsArticle from 'pages/News/View';
import PressArticle from 'pages/Press';
import ProfileOverview from 'pages/Profile';
import NotFound from 'pages/Public/NotFound';
import SomeEdit from 'pages/Some/Edit';
import StoryEdit from 'pages/Story/Edit';
import Story from 'pages/Story/View';
import ChannelEdit from 'pages/Topics/Edit';
import Channels from 'pages/Topics/View';
import {
  AlertCreate,
  HeroBannerCreate,
  HeroBannerEdit,
  Home,
  Jobs,
  MyMoment,
  MyMomentCreateOrEdit,
  NewsletterBannerCreate,
  NewsletterBannerEdit,
  ProductOfTheWeekCreateOrEdit,
  ProductOfTheWeekGallery,
  Profile,
} from 'pagesAvenue';
import { Redirect, Route, Switch } from 'react-router-dom';

const Root = () => {
  const user = useUser();
  const libraryRoot = useLibraryRoot();
  const redirects = useRedirects();

  return (
    <OrganizationDataProvider>
      <PageHeader signedIn={true} showBlueBackground={true}>
        <UserConsumer>
          {({ navigationKey }) => (
            <MenuContent key={`navigation${navigationKey}`} />
          )}
        </UserConsumer>
      </PageHeader>
      <main className="main-content">
        <Switch>
          <Route
            exact
            path="/"
            render={() => (
              <GlobalWebcastProvider>
                <HomeBannerProvider>
                  <Home />
                </HomeBannerProvider>
              </GlobalWebcastProvider>
            )}
          />
          <Redirect
            exact
            path={AvenueRouteEnum.Profile}
            to={AvenueRouteEnum.ProfilePreferences}
          />
          <Route
            exact
            path={AvenueRouteEnum.ProfilePreferences}
            component={Profile}
          />
          <Route
            exact
            path={AvenueRouteEnum.ProfileSubscriptions}
            component={Profile}
          />
          <Route
            exact
            path={AvenueRouteEnum.ProfileManageFeed}
            component={Profile}
          />
          <Route
            exact
            path={AvenueRouteEnum.ProfileManageLibrary}
            component={Profile}
          />
          <Route
            exact
            path={AvenueRouteEnum.ProfileManageInsightsDatabase}
            component={Profile}
          />
          {(user.isAdmin || user.isSuperUser) && (
            <Route
              exact
              path={AvenueRouteEnum.ProfileManageBanners}
              component={Profile}
            />
          )}
          {(user.isSuperUser || user.isNotificationUser) && (
            <Route
              exact
              path={AvenueRouteEnum.ProfileManageAlerts}
              component={Profile}
            />
          )}
          <Route exact path={AvenueRouteEnum.Jobs} component={Jobs} />
          <Route exact path="/404" component={NotFound} />
          <Route exact path="/nouser" component={NoUser} />
          <Route exact path="/find" component={Find} />
          <Route path="/feed">
            <UserConsumer>
              {({
                divisionId,
                divisionName,
                segmentId,
                segmentName,
                countryId,
                countryName,
                departmentId,
                departmentName,
                siteId,
                siteName,
                companyId,
                companyName,
                isLoading,
              }) =>
                isLoading ? null : (
                  <FeedProvider
                    divisionId={divisionId}
                    divisionName={divisionName}
                    segmentId={segmentId}
                    segmentName={segmentName}
                    countryId={countryId}
                    countryName={countryName}
                    departmentId={departmentId}
                    departmentName={departmentName}
                    siteId={siteId}
                    siteName={siteName}
                    companyId={companyId}
                    companyName={companyName}
                  >
                    <Switch>
                      <Route
                        path="/feed/news/:type(create)"
                        component={NewsEdit}
                      />
                      <Route
                        path="/feed/news/:id/:type(edit)"
                        component={NewsEdit}
                      />
                      <Route
                        exact
                        path="/feed/news/:id"
                        component={NewsArticle}
                      />
                      <Route
                        exact
                        path="/feed/press/:id"
                        component={PressArticle}
                      />
                      <Route
                        path="/feed/blog/:type(create)"
                        component={BlogEdit}
                      />
                      <Route
                        path="/feed/blog/:id/:type(edit)"
                        component={BlogEdit}
                      />
                      <Route path="/feed/blog/:id" component={Blog} />
                      <Route
                        path="/feed/story/:type(create)"
                        component={StoryEdit}
                      />
                      <Route
                        path="/feed/story/:id/:type(edit)"
                        component={StoryEdit}
                      />
                      <Route path="/feed/story/:id" component={Story} />
                      <Route exact path="/feed" component={Feed} />
                      <Route exact path="/feed/all" component={Feed} />
                      <Route exact path="/feed/topics" component={Feed} />

                      <Redirect to="/feed" />
                    </Switch>
                  </FeedProvider>
                )
              }
            </UserConsumer>
          </Route>

          <Route exact path="/people/:id" component={ProfileOverview} />
          <Route path="/topics/:type(create)" component={ChannelEdit} />
          <Route path="/topics/:id/:type(edit)" component={ChannelEdit} />
          <Route path="/some/:type(create)" component={SomeEdit} />
          <Route path="/some/:type(edit)" component={SomeEdit} />

          {user.isAdmin && (
            <Route
              path={`${AvenueRouteEnum.NewsletterBannerCreate}`}
              component={NewsletterBannerCreate}
            />
          )}
          {user.isAdmin && (
            <Route
              path={`${AvenueRouteEnum.NewsletterBannerEdit}/:id`}
              component={NewsletterBannerEdit}
            />
          )}
          {user.isAdmin && (
            <Redirect
              path={AvenueRouteEnum.NewsletterBanner}
              to={AvenueRouteEnum.NewsletterBannerCreate}
            />
          )}

          {user.isSuperUser && (
            <Route
              path={`${AvenueRouteEnum.HeroBannerCreate}`}
              component={HeroBannerCreate}
            />
          )}
          {user.isSuperUser && (
            <Route
              path={`${AvenueRouteEnum.HeroBannerEdit}/:id`}
              component={HeroBannerEdit}
            />
          )}
          {user.isSuperUser && (
            <Redirect
              path={AvenueRouteEnum.HeroBanner}
              to={AvenueRouteEnum.HeroBannerCreate}
            />
          )}
          {(user.isSuperUser || user.isNotificationUser) && (
            <Route path={AvenueRouteEnum.AlertCreate} component={AlertCreate} />
          )}

          <Route
            path="/productoftheweek/:type(create)"
            component={ProductOfTheWeekCreateOrEdit}
          />
          <Route
            path="/productoftheweek/:id/:type(edit)"
            component={ProductOfTheWeekCreateOrEdit}
          />
          <Route
            path={AvenueRouteEnum.ProductOfTheWeekGallery}
            component={ProductOfTheWeekGallery}
          />

          <Route path="/topics/:id" component={Channels} />
          <Route
            exact
            path="/my-moment"
            render={() => (
              <MyMomentProvider>
                <MyMoment />
              </MyMomentProvider>
            )}
          />
          <Route
            path="/my-moment/:type(create)"
            component={MyMomentCreateOrEdit}
          />
          <Route
            path="/my-moment/:type(edit)"
            component={MyMomentCreateOrEdit}
          />
          <Route
            exact
            path="/campaign/crossword"
            component={CrossWordCampaign}
          />
          <Route exact path="/admin" component={Administration} />

          <Route path="/library-redirect/:id" component={LibraryIdRedirect} />

          {!redirects.isLoading && (
            <Switch>
              {(redirects.redirectExact || []).map((redirect, i) => (
                <Redirect
                  exact
                  path={redirect.from}
                  to={redirect.to}
                  key={`redirect-exact-${i}`}
                />
              ))}
              {(redirects.redirectAll || []).map((redirect, i) => (
                <RedirectAll
                  from={redirect.from}
                  to={redirect.to}
                  key={`redirect-all-${i}`}
                />
              ))}
              <Route exact path="/library">
                <Redirect to={`/library${libraryRoot.myLibraryPath}`} />
              </Route>
              <Route path="/library">
                {libraryRoot.isLoading ? null : (
                  <Route path="/library/:route*" component={Library} />
                )}
              </Route>
              <Route path="/group-library">
                {libraryRoot.isLoading ? null : (
                  <Route path="/group-library/:route*" component={Library} />
                )}
              </Route>
              <Route exact path="*">
                <Redirect to="/404" />
              </Route>
            </Switch>
          )}
        </Switch>
      </main>
    </OrganizationDataProvider>
  );
};

export { Root };
