import OutlinedInput, { OutlinedInputProps } from '@mui/material/OutlinedInput';
import RadioGroup, { RadioGroupProps } from '@mui/material/RadioGroup';
import Stack from '@mui/material/Stack';
import { Theme } from '@mui/material/styles';
import Switch, { SwitchProps } from '@mui/material/Switch';
import { SxProps } from '@mui/system/styleFunctionSx';
import {
  DatePicker,
  DateTimePicker,
  MultiSelect,
  SingleSelect,
  SiteSearchSelect,
  UserSearchSelect,
} from 'componentsNew';
import { DatePickerProps } from 'componentsNew/DatePickers/DatePicker';
import { DateTimePickerProps } from 'componentsNew/DatePickers/DateTimePicker';
import { MultiSelectProps } from 'componentsNew/Select/MultiSelect';
import { SingleSelectProps } from 'componentsNew/Select/SingleSelect';
import { SiteSearchSelectProps } from 'componentsNew/Select/SiteSearchSelect';
import { UserSearchSelectProps } from 'componentsNew/Select/UserSearchSelect';
import React, { ReactElement, useMemo } from 'react';

import { FormFieldHelper, FormFieldHelperProps } from './FormFieldHelper';
import { FormFieldLabel, FormFieldLabelProps } from './FormFieldLabel';

/**
 * FormFieldWrapper can be used to wrap form components to help handle label, errors,
 * accessibility etc. We do this by applying some extra props to the wrapped element.
 *
 * If you're about to use this with a form component not listed in the switch/case
 * further down, you might need to add a case for it and do some tweaking when cloning
 * the element so that the extra props (error, aria labels etc) are applied correctly.
 */

export type FormFieldWrapperProps = {
  id?: string;
  label: string;
  labelSize?: FormFieldLabelProps['size'];
  labelComponent?: FormFieldLabelProps['component'];
  labelPlacement?: 'top' | 'right' | 'left';
  hideLabel?: boolean;
  fullWidth?: boolean;
  error?: string | string[];
  info?: string | string[];
  warning?: string | string[];
  sx?: SxProps<Theme>;
  children: React.ReactElement;
};

const FormFieldWrapper = ({
  id,
  label,
  labelSize = 'large',
  labelPlacement = 'top',
  labelComponent,
  hideLabel,
  fullWidth = true,
  error = [],
  info = [],
  warning = [],
  sx,
  children,
}: FormFieldWrapperProps) => {
  const fieldId = useMemo(() => (id ? `${id}-input` : undefined), [id]);

  const fieldHelpers = useMemo(() => {
    if (!fieldId) {
      return [];
    }
    const infoHelpers: FormFieldHelperProps[] = [
      ...(Array.isArray(info) ? info : [info]),
    ].map((text, i) => ({
      id: `${fieldId}-info-${i}`,
      type: 'information',
      children: text,
      open: true,
    }));

    const warningHelpers: FormFieldHelperProps[] = [
      ...(Array.isArray(warning) ? warning : [warning]),
    ].map((text, i) => ({
      id: `${fieldId}-warning-${i}`,
      type: 'warning',
      children: text,
      open: true,
    }));

    const errorHelpers: FormFieldHelperProps[] = [
      ...(Array.isArray(error) ? error : [error]),
    ].map((text, i) => ({
      id: `${fieldId}-error-${i}`,
      type: 'critical',
      children: text,
      open: true,
    }));
    return [...infoHelpers, ...warningHelpers, ...errorHelpers];
  }, [error, fieldId, info, warning]);

  const fieldElement = useMemo(() => {
    if (!React.isValidElement(children)) {
      return null;
    }
    const isError = error.length > 0;
    const ariaDescribedBy =
      fieldHelpers.map((fieldHelper) => fieldHelper.id).join(' ') || undefined;

    switch (children.type) {
      case DatePicker:
        const datePicker = children as ReactElement<DatePickerProps>;
        return React.cloneElement(datePicker, {
          error: isError,
          inputProps: { id: fieldId, 'aria-describedby': ariaDescribedBy },
        });
      case DateTimePicker:
        const dateTimePicker = children as ReactElement<DateTimePickerProps>;
        return React.cloneElement(dateTimePicker, {
          error: isError,
          inputProps: { id: fieldId, 'aria-describedby': ariaDescribedBy },
        });
      case MultiSelect:
        const multiSelect = children as ReactElement<MultiSelectProps>;
        return React.cloneElement(multiSelect, {
          error: isError,
          inputProps: { id: fieldId, 'aria-describedby': ariaDescribedBy },
        });
      case OutlinedInput:
        const outlinedInput = children as ReactElement<OutlinedInputProps>;
        return React.cloneElement(outlinedInput, {
          error: isError,
          inputProps: { id: fieldId, 'aria-describedby': ariaDescribedBy },
        });
      case RadioGroup:
        const radioGroup = children as ReactElement<RadioGroupProps>;
        return React.cloneElement(radioGroup, {
          id: fieldId,
          'aria-describedby': ariaDescribedBy,
        });
      case SingleSelect:
        const singleSelect = children as ReactElement<SingleSelectProps>;
        return React.cloneElement(singleSelect, {
          error: isError,
          inputProps: { id: fieldId, 'aria-describedby': ariaDescribedBy },
        });
      case SiteSearchSelect:
        const siteSearchSelect =
          children as ReactElement<SiteSearchSelectProps>;
        return React.cloneElement(siteSearchSelect, {
          error: isError,
          inputProps: { id: fieldId, 'aria-describedby': ariaDescribedBy },
        });
      case Switch:
        const switchh = children as ReactElement<SwitchProps>;
        return React.cloneElement(switchh, {
          inputProps: { id: fieldId, 'aria-describedby': ariaDescribedBy },
        });
      case UserSearchSelect:
        const userSearchSelect =
          children as ReactElement<UserSearchSelectProps>;
        return React.cloneElement(userSearchSelect, {
          error: isError,
          inputProps: { id: fieldId, 'aria-describedby': ariaDescribedBy },
        });

      default:
        return children;
    }
  }, [children, error.length, fieldHelpers, fieldId]);

  return (
    <Stack
      sx={(theme) => ({
        position: 'relative',
        gap: theme.spacing('xxxs'),
        ...(fullWidth && { width: '100%' }),
      })}
    >
      <Stack
        sx={[
          (theme) => ({
            gap: theme.spacing(labelSize === 'large' ? 'xs' : 'xxxs'),
            ...(labelPlacement === 'left' && {
              flexFlow: 'row',
              label: { margin: 'auto auto auto 0' },
            }),
            ...(labelPlacement === 'right' && {
              flexFlow: 'row-reverse',
              label: { margin: 'auto auto auto 0' },
            }),
          }),
          ...(Array.isArray(sx) ? sx : [sx]),
        ]}
      >
        <FormFieldLabel
          size={labelSize}
          component={labelComponent}
          htmlFor={fieldId}
          hide={hideLabel}
        >
          {label}
        </FormFieldLabel>
        {fieldElement}
      </Stack>
      {fieldHelpers.map((fieldHelper) => (
        <FormFieldHelper
          id={fieldHelper.id}
          key={fieldHelper.id}
          type={fieldHelper.type}
          open={fieldHelper.open}
        >
          {fieldHelper.children}
        </FormFieldHelper>
      ))}
    </Stack>
  );
};

export { FormFieldWrapper };
